

import {
    useCallback,
    useRef,
    useState,
} from "react";

import {
    Paper,
    InputBase
} from '@mui/material';

import IconButton from '@mui/material/IconButton';

import {
    Search as SearchIcon,
    HighlightOff as XIcon
} from '@mui/icons-material';


import { createLSFeatureFlag } from "../util/storage";
import { System, debounce } from "../Reusable";
import { SearchInvokeAsync, isServerErrorResponse } from "../adapters/ApiSchema";
import { queryKnownMatchMedia } from "../adapters/windowUtils";
import { withPreventDefault } from "../adapters/Eventing";
import React from "react";

export const contentSearchFeatureFlagKey = "allowContentSearch";

const contentSearchFeatureFlag = createLSFeatureFlag(contentSearchFeatureFlagKey, true, true);


export type ContentSearchProps = {
    focus: System.PropWrapper<boolean>
    cardClassName: string
    categories?: string[]
    onSearch: SearchInvokeAsync
}

// nice to have: server flag forcing content search off
export const ContentSearch = ({ cardClassName, focus: [isFocus, setIsFocus], onSearch }: ContentSearchProps) => {

    const [lastSearch, setLastSearch] = useState<string>("");
    const [searchText, setSearchText] = useState<string>(lastSearch ?? "");
    const [awaitingSearchResult, setAwaitingSearchResult] = useState<boolean>(false);
    // const wasLongSearchOnSmallDevice = useRef(false);
    // const input = useRef<HTMLInputElement | undefined>(undefined);

    let isSmallDevice = queryKnownMatchMedia('isSmallDevice') ?? false;
    // console.log('rerendering onSearch')

    // WARN: this may be under minding the search loading indicator and wait
    // also this may be getting aborted
    // if the content search decides it needs to rerender while this is in flight
    let onSearchClick = async (searchText: string) => {
        console.log('searching for', searchText);
        setAwaitingSearchResult(true);
        let result = await onSearch(searchText);
        if (!isServerErrorResponse(result)) {
            console.log('storing')
            setLastSearch(searchText);
        }
        setIsFocus(false);
        setAwaitingSearchResult(false);
    };

    // this was set as a callback with no dependencies, if this starts acting strange, maybe set it back
    let refocus = debounce(() => { console.log('refocus'); setIsFocus(true); });

    let shouldShowHelper: boolean = lastSearch !== '';

    let showContentSearch = contentSearchFeatureFlag.getter();

    let onBlurCb = useCallback(() => {
        if (awaitingSearchResult) {
            // console.log('blur unfocus blocked by awaiting search result')
        } else {
            // console.log('blur');
            setIsFocus(false);
        }
    }, [awaitingSearchResult]);

    if (showContentSearch !== true) {
        return (<div />);
    }

    // if the search is empty and is not 1-2 chars
    let hasValidSearch = searchText === "" || (searchText && searchText.length && (searchText.length > 2 || false));
    let hasValidLastSearch = !System.String.isNullOrEmpty(lastSearch);


    if (!isFocus)
        return (
            <IconButton type="button" sx={{ p: '10px' }}
                style={{ color: "#fff" }}
                aria-label="search" onClick={() => setIsFocus(true)}>
                <SearchIcon />
            </IconButton>
        );

    let searchDisabled: boolean = awaitingSearchResult || lastSearch === searchText || !hasValidSearch || (!hasValidLastSearch && searchText === "") || false;

    console.log('ContentSearch', JSON.stringify({ searchText, searchDisabled, isFocus, awaitingSearchResult }))
    // if the input type changed from input to text area



    let compensateForLongSearch: boolean = (isSmallDevice && searchText && searchText.length > 30) || false;

    // this assumption fixes the issue with the textbox being off screen 
    // otherwise the user on a small device would no longer be able to see what they are typing on long searches
    // this may be a bad value on smaller devices than tested
    let maxPaperWidth = isFocus ? (isSmallDevice ? 320 : 505) : 350;
    // console.log('CS', { awaitingSearchResult, lastSearch, searchText, hasValidSearch, hasValidLastSearch, searchDisabled });
    // https://mui.com/material-ui/react-text-field/
    return ( // using component form to capture enter key
        <Paper
            component={'form'}
            onSubmit={withPreventDefault(() => !searchDisabled ? onSearchClick(searchText) : {})}
            elevation={14}
            onBlur={() => setTimeout(() => onBlurCb(), 150)}
            className={cardClassName}
            sx={{ p: '2px 4px', height: isFocus ? '46px' : isSmallDevice ? undefined : '40px', display: 'flex', alignItems: 'center', width: maxPaperWidth, marginTop: '5px', marginBottom: '5px' }}
        >
            {awaitingSearchResult ? <div>loading...</div> :
                <React.Fragment>

                    <IconButton disabled={searchText === ""} title={"Clear Search"} type="button" sx={{ p: '10px' }} onClick={() => { setSearchText(""); setLastSearch(""); onSearchClick(""); }} aria-label="search">
                        <XIcon />
                    </IconButton>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search OneView Home"
                        aria-describedby={shouldShowHelper ? 'my-helper-text' : ''}
                        inputProps={{ 'aria-label': 'search oneView home' }}
                        // size={compensateForLongSearch ? 'medium' : 'small'}
                        // multiline={compensateForLongSearch}
                        // rows={2}
                        fullWidth={compensateForLongSearch}
                        // minRows={2}
                        // inputRef={input}
                        value={searchText}
                        autoFocus={isFocus}
                        onFocus={() => setIsFocus(true)}
                        // set timeout because the search click will not bubble if this blur forces a rerender
                        // before we get the click handler started
                        onChange={(e) => (setSearchText(e.target.value))}
                    />
                    {/* {shouldShowHelper ?
                        <FormHelperText id="my-helper-text" onClick={() => refocus(true)} >'{lastSearch}' Search Results</FormHelperText > : ''} */}
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" disabled={searchDisabled} onClick={() => onSearchClick(searchText)}>
                        <SearchIcon />
                    </IconButton>
                </React.Fragment>
            }
        </Paper>
    );
}