import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";

import useEventListener from "../../util/useEventListener";

import { styled } from '@mui/material/styles';
import { Card, CardHeader, CardContent, CardActions, Collapse, Avatar, Typography, Skeleton, CircularProgress, Popover, useMediaQuery, Paper } from '@mui/material';

import {
  Share as ShareIcon,
  ExpandMore as ExpandMoreIcon,
  ContentCopy as ContentCopyIcon,
  Check as CheckIcon,
  ThumbUp as ThumbUpIcon,
  ThumbUpOutlined as ThumbUpOIcon
} from '@mui/icons-material';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red, grey } from '@mui/material/colors';
import { useTheme } from "@mui/material/styles";

import { registerServerError } from "../../store/error-slice";

// see also https://www.geeksforgeeks.org/lazy-loading-in-react-and-how-to-implement-it/
import LazyLoad from 'react-lazyload';

import { newDcpsService } from "../../util/Services";
import { SearchInvokeAsync, SearchInvokeResult, ServerErrorResponse, isServerErrorResponse } from "../../adapters/ApiSchema";
import { withScrollingTarget } from "../../adapters/Eventing";
import { isWindowBottom, queryKnownMatchMedia, windowOpen } from "../../adapters/windowUtils";
import { System, debounce, debounceAsync } from "../../Reusable";

import { END_OF_FEED, FetchInit, FetchRequest, HandleReplyResult, LikeInfo, PostFetchArgs, PostFetchNotifyArgs, ServerPostItem, fetchPosts, getItemsNext } from "../../adapters/ContentStream";

export interface SearchEditorProps {
  // searchText: string,
  // setSearchText: System.Action1<string>,
  // lastSearch: string,
  // setLastSearch: System.Action1<string>
  onSearch: SearchInvokeAsync
}

export type SearchInvokeRef = { currentSearch: string | null, searchInvokeRef: React.MutableRefObject<SearchInvokeAsync | null> }
// scroll into view, triggering like loading options:
// https://spacejelly.dev/posts/how-to-trigger-a-function-when-scrolling-to-an-element-in-react-intersection-observer
// https://stackoverflow.com/questions/58328428/trigger-event-when-a-each-div-has-been-scrolled-into-view-and-update-state

const THUMB_HEIGHT = '100%';
const MAX_HEIGHT = 600;
export const MAX_WIDTH = 650;


const PREFIX = 'CDS';

const classes = {
  feed: `${PREFIX}-feed`,
  shimmer: `${PREFIX}-shimmer`,
  hover: `${PREFIX}-hover`,
  like: `${PREFIX}-like`,
  likePostCopy: `${PREFIX}-likePostCopy`,
  loadIcon: `${PREFIX}-loadIcon`,
  share: `${PREFIX}-share`,
  sharePostCopy: `${PREFIX}-sharePostCopy`,
  container: `${PREFIX}-container`,
  containerShimmer: `${PREFIX}-containerShimmer`,
  blur: `${PREFIX}-blur`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.feed}`]: {
    display: "flex",
    flexDirection: "column",
    width: MAX_WIDTH,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    }
  },
  [`& .${classes.shimmer}`]: {
    top: 70
  },
  [`& .${classes.hover}`]: {
    cursor: "pointer",
  },
  [`& .${classes.like}`]: {
    display: 'flex',
    width: 175,
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['color', 'background-color', 'padding-left'], {
      duration: theme.transitions.duration.complex,
    }),
    cursor: "pointer",
  },

  [`& .${classes.likePostCopy}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    alignItems: 'center',
    paddingLeft: 10,
    cursor: "default",
    pointerEvents: "none",
  },
  [`& .${classes.loadIcon}`]: {
    alignSelf: "center",
    marginBottom: 20,
  },

  [`& .${classes.share}`]: {
    display: 'flex',
    width: 175,
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['color', 'background-color', 'padding-left'], {
      duration: theme.transitions.duration.complex,
    }),
    cursor: "pointer",
  },

  [`& .${classes.sharePostCopy}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    alignItems: 'center',
    paddingLeft: 10,
    cursor: "default",
    pointerEvents: "none",
  },
  [`& .${classes.container}`]: {
    marginBottom: 20,
    boxShadow: "1px 1px 10px #dbdbdb",
    [theme.breakpoints.down('sm')]: {
      maxWidth: 318
    }
  },
  [`& .${classes.containerShimmer}`]: {
    width: '85vw',
    maxWidth: MAX_WIDTH,
  },
  [`& .${classes.blur}`]: {
    position: 'relative',
    width: '100%',
    height: '80px',
    marginTop: '-60px',
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, white 80%, white 100%)',
  }
}));

/*
    Displayed while loading
*/
const Shimmer = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Card raised={true} className={`${classes.container} ${classes.containerShimmer}`}>
      <CardHeader //TODO get avatar from backend
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        action={null}
        title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
        min-width={'90vw'} // Prevent card from shrinking too much
      />
      <Skeleton height={sm ? 300 : 500} animation="wave" variant="rectangular" />
      <CardContent>
        <React.Fragment>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </React.Fragment>
      </CardContent>
    </Card>
  );
}

export const SS_PREF = "CDS";

const dummyItems = new Array(9).fill(0);

// hot reload is causing nextUrl to fire getting the next items
const Feed = ({ searchInvokeRef, currentSearch }: SearchInvokeRef) => {
  const [postItems, setPostItems] = useState<ServerPostItem[]>([]);
  const [nextUrl, setNextUrl] = useState<string>("");
  // should we use loading or initially loading for search change?
  const [initiallyLoading, setInitiallyLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false);

  let isSmallDevice = queryKnownMatchMedia('isSmallDevice') ?? false;
  // console.log("Feed", { items: postItems.length, hasInvokeRef: searchInvokeRef.current !== null });
  // console.log("Feed", currentSearch);

  const dispatch = useDispatch();

  // Reset scroll
  useEffect(() => {
    // console.log('Feed re-init');
    window.scrollTo(0, 0);
    //whoAmI(newToken).then(res=>console.log(res));
    return () => {
      window.scrollTo(0, 0);
      // Start: clean up for hot reload
      // attempted fixes for hot reload forcing a pull of the next batch of items
      setPostItems([]);
      setNextUrl("");
      setInitiallyLoading(true);
      setLoading(false);

      // end: clean up for hot reload
    };
  }, []);

  const processReply = (dispatch: any, existingItems: ServerPostItem[], searchChanged: boolean, hrr: HandleReplyResult | undefined): void | ServerErrorResponse => {
    var keepPrevious = existingItems && existingItems.length > 0 && !searchChanged;
    if (hrr === undefined) return;
    if (isServerErrorResponse(hrr)) {
      //  registerError(dispatch, res, "CDS", false);
      registerServerError({ dispatch, e: hrr, type: "CDS", fatal: false });
      return hrr;
    } else {
      if (hrr.initiallyLoading !== undefined)
        setInitiallyLoading(hrr.initiallyLoading);
      if (hrr.loading !== undefined)
        setLoading(hrr.loading);
      if (hrr.nextUrl !== undefined)
        setNextUrl(hrr.nextUrl);
      if (hrr.newItems && hrr.newItems.length > 0) {
        // console.log('setting postItems!');
        var nextItems = getItemsNext(keepPrevious, existingItems, hrr.newItems);
        setPostItems(nextItems);
      } else {
        console.log('process reply without new items');
      }
    }
  };

  const pfHandlers: Omit<PostFetchNotifyArgs, keyof PostFetchArgs> = {
    // onBeforeLoad: (searchText) => { console.log('pfHandlers OnBeforeLoad',); setLoading(true); },
    onNonContinue: () => { console.log('pfHandlers searchChange'); setPostItems([]); }
  }

  const loadPosts = useCallback((pfa: PostFetchArgs) =>
    fetchPosts({ ...pfa, ...pfHandlers }).then(x => (x && !isServerErrorResponse(x) && processReply(dispatch, postItems, x.searchChanged, x)) || undefined),
    [dispatch, postItems, pfHandlers]);


  const setItemLike = useCallback((id: string, nextLikeInfo: LikeInfo) => {
    let likeInfoPart: Partial<ServerPostItem> = {
      likeInfo: nextLikeInfo
    }

    setPostItems(items => items.map(x => x.id === id ? Object.assign({}, x, likeInfoPart) : x));

  }, [setPostItems]);

  const onLikeServerRequest = (item: ServerPostItem): Promise<void> | undefined => {
    if (item.likeInfo === undefined) return;
    // in case iLike is null or undefined
    let iLike = item.likeInfo.iLike === true;

    let url = iLike ? item.removeLikeUrl : item.addLikeUrl;
    if (!url) {
      console.error('onLikeClick failed to read url', item.id, item.likeInfo);
      return;
    }

    return newDcpsService.setLike(url).then(res => {
      if (isServerErrorResponse(res)) {
        console.error('')
      } else {
        setItemLike(item.id, { iLike: !iLike, likes: res.TotalLikes })
        // console.log('finished set like!');
      }
    });
  };

  const onLikeClick = (item: ServerPostItem): Promise<void> | undefined => {
    if (item.likeInfo === undefined) return;
    let likeInfo = item.likeInfo;
    let iLike = item.likeInfo.iLike;

    // eagerly optimistically adjust the icon
    setItemLike(item.id, { iLike: !iLike, likes: likeInfo.likes + (iLike ? -1 : 1) })
    return onLikeServerRequest(item);
  };

  const onLikeLoadRequest = (item: ServerPostItem) => {
    if (item.likeInfo !== undefined) return;
    if (item.getLikeInfoUrl) {

      newDcpsService.getLikeInfo(item.getLikeInfoUrl).then(resp => {
        // console.log('like resp', resp);
        if (isServerErrorResponse(resp)) {

        } else {
          let itemLikeInfo: Partial<ServerPostItem> = {
            likeInfo: {

              likes: resp.TotalLikes,
              iLike: resp.HasLiked
            }
          };

          let itemX: ServerPostItem = Object.assign({}, item, itemLikeInfo);

          setPostItems(items => {
            var nextItems = items.map(x => x.id === itemX.id ? itemX : x);
            console.log('nextItems', nextItems);
            return nextItems;
          }
          );
        }
      });
    } else {
      console.error("getLikeInfoUrl", item.id, item.getLikeInfoUrl);
    }
  };

  // TODO: missing dependencies?
  useEffect(() => {
    // If it's our initial load without cache, load with shimmer
    // console.log('CDS reloading items', { loading });
    if (!loading) {
      setLoading(true);
      let pfa: FetchInit = { state: "init", searchText: "" };
      loadPosts(pfa);
    } else {
      console.log("CDS feed with already loading");
    }
  }, []); // adding the requested dependencies make this constantly refire

  const onScrollBottom = useCallback(debounce((element: unknown) => {
    if (element && isWindowBottom()) {
      // console.log('element and window bottom', loading, initiallyLoading);
      if (!loading && !initiallyLoading) {
        console.log("bottom, loading:", nextUrl);
        // should we force the scroll to 1 above whatever is triggering this state or will the user's force scroll result in thrashing the window?
        window.scrollBy(0, -10);
        // do we care about last search? we're using nextUrl
        if (!loading) {
          loadPosts({ nextUrl: nextUrl });
        }
      }
    }

  }, 1000), [nextUrl, loading, initiallyLoading]);

  let searchInvokeDelegate: SearchInvokeAsync = async (searchText: string) => {
    console.log('searchInvokeRef', searchText, loading);
    if (!loading) {
      let pfa: FetchRequest = {
        state: "req",
        searchText
      }
      let loaded = await loadPosts(pfa);
      return loaded;
    } else {
      console.log("CDS feed req with already loading");
    }
  };

  // <string,ReturnType<typeof searchInvokeDelegate>>
  let debouncedSearchInvoke: SearchInvokeAsync = debounceAsync<string, SearchInvokeResult>(searchInvokeDelegate);
  searchInvokeRef.current = debouncedSearchInvoke;
  // console.log('set searchInvokeRef current')

  // Load again when we scroll to the bottom of the page (DESKTOP)
  useEventListener(window, 'scroll', withScrollingTarget((element: unknown) => {
    onScrollBottom(element);
  }), nextUrl);

  let searchBanner = !initiallyLoading ? System.String.appendIfValue(System.String.quotedIfValue(currentSearch), "Search Results", true) : '';

  return (<>
    {initiallyLoading ?
      <Root data-file="CDS" data-method="Feed" data-state="initiallyLoading">
        <div className={classes.shimmer}>
          {dummyItems.map((_, index) => (
            <Shimmer key={index} />
          ))}
        </div>
      </Root>
      :
      <Root data-file="CDS" data-method="Feed">
        {
          !!searchBanner ? <header style={{ position: "sticky", top: 60, width: "100%", zIndex: 999, marginBottom: 10 }}><Paper><p style={{ padding: 5 }}>{searchBanner}</p></Paper></header> : undefined
        }
        <div className={classes.feed}>
          {postItems.map((item, index) => (
            <PostItem key={index} {...item} isSmallDevice={isSmallDevice} onLikeUnlikeClick={() => onLikeClick(item)} onLoadRequest={() => onLikeLoadRequest(item)} />
          ))}
          {loading && <CircularProgress className={classes.loadIcon} />}
          {nextUrl === END_OF_FEED && <Typography align='center' variant='body2' style={{ marginBottom: 10 }} color={grey[400]}>End of Feed</Typography>}
        </div>
      </Root >
    }
  </>)

}


// // perhaps a screen width dependent length would be better, or a fixed width font
// const limit = 40;
// // https://johnresig.com/blog/injecting-word-breaks-with-javascript/
// const bigWordRegExp = RegExp("(\\w{" + (limit - 1) + "})(\\w)", "g");

// const mungeText = (text: string) => {
//   return text.replace(/[^\sa-zA-Z0-9]{50,}/g, "").replace(bigWordRegExp, function (all, text, char) {
//     return text + "-" + char;
//   });
// };

// {thumbnail:string, title:any, location:string, link:string, activity:string, provider:string}
const PostItem = (props: ServerPostItem & { isSmallDevice: boolean, onLikeUnlikeClick: () => (Promise<void> | undefined), onLoadRequest: Function }) => {

  // Used for details flyout
  const [expanded, setExpanded] = useState(false);

  // Used for fading in thumbnails when they are loaded
  const [thumbLoaded, setThumbLoaded] = useState<boolean>(false);

  // Used for favorite button
  const [likeUnlikeInFlight, setLikeUnlikeInFlight] = useState<boolean>(false); //TODO replace with backend call

  // Used for pop out of share button
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(props.link);
    setCopied(true);
  }
  const handleOpenShare = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setCopied(false);
  }


  // Collapse or expand description to properly use space
  useEventListener(window, 'resize', () => {
    if (window.innerWidth < 700 && expanded) {
      setExpanded(false);
    }
  });

  const onLikeUnlikeClick = useCallback(async () => {
    if (!props.likeInfo) return;
    if (likeUnlikeInFlight) return;
    // console.log('onLikeUnlikeClick');
    setLikeUnlikeInFlight(true);
    await props.onLikeUnlikeClick();
    // the below call assumes the above call blocks, instead of being async
    // console.log('fin onLikeUnlikeClick');
    setLikeUnlikeInFlight(false);
  }
    , [likeUnlikeInFlight, props.likeInfo, props.onLikeUnlikeClick]);

  const hasContent = props.summary;

  return (
    <Root data-file="CDS" data-method="PostItem">

      <Card variant="outlined" className={classes.container}>
        <CardHeader //TODO get avatar from backend
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="avatar">
              {props.provider.substring(0, 1).toUpperCase()}
            </Avatar>
          }
          title={props.provider}
          subheader={<span title={props.dateTitle}>{props.date}</span>}
        />
        <CardThumbnail
          className={classes.hover}
          loaded={thumbLoaded}
          isSmallDevice={props.isSmallDevice}
          onLoad={() => setThumbLoaded(true)}
          src={props.thumbnail}
          onClick={() => windowOpen(props.link, "_blank", "")}
        />
        <CardContent>
          <Typography variant="h6" color="text.secondary">
            {props.title}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Share
            aria-label="share"
            anchorEl={anchorEl}
            onOpen={handleOpenShare}
            onClose={() => setAnchorEl(null)}
          >
            {!copied ? <div className={classes.share} onClick={handleCopy}>
              <IconButton aria-label='click to copy content'>
                <ContentCopyIcon />
              </IconButton>
              <Typography align='left' sx={{ p: 2, color: 'currentcolor' }}>Copy Link</Typography>
            </div>
              :
              <div className={`${classes.share} ${classes.sharePostCopy}`}>
                <CheckIcon sx={{ fill: 'white' }} />
                <Typography align='left' sx={{ p: 2 }}>Link Copied</Typography>
              </div>}

          </Share>
          {/* {props.likeInfo == undefined && !props.likeLoading ? <img src="javascript:" loading="lazy" /> : <div />} */}
          <Like
            aria-label="like"
            id={props.id}
            likeInfo={props.likeInfo}
            onClick={likeUnlikeInFlight ? () => { } : onLikeUnlikeClick}
            onLoadRequest={props.onLoadRequest}
          >
          </Like>
          {hasContent && <ExpandMore
            expand={expanded}
            onClick={() => setExpanded(!expanded)}
            aria-expanded={expanded}
            aria-label="show more"
          />}
        </CardActions>
        {hasContent && <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography padding="0 10px" align="justify" variant="body1" sx={{ wordWrap: "anywhere" }} >
              {props.summary}
            </Typography>
            <div className={classes.blur} />
            <Typography sx={{ textAlign: "center", display: "block", cursor: "pointer" }} variant="button" onClick={() => windowOpen(props.link, "_blank", "")}>
              Click to read more
            </Typography>
          </CardContent>
        </Collapse>}
      </Card>
    </Root>
  );
}

/************************************************************************/
/************************* HELPER COMPONENTS ****************************/
/************************************************************************/
interface ExpandMoreProps extends IconButtonProps { expand: boolean; }
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return (<IconButton {...other} >
    <ExpandMoreIcon />
  </IconButton>);
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto !important",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface ShareProps extends IconButtonProps { anchorEl: any, onOpen: any, onClose: any }

const Share = styled((props: ShareProps) => {
  const { anchorEl, onOpen, onClose, onClick, ...other } = props;
  return (<React.Fragment>
    <IconButton
      {...other} data-file="CDS"
      onClick={onOpen}>
      <ShareIcon />
    </IconButton>
    <Popover
      id={Boolean(anchorEl) ? 'share-popover' : undefined}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {props.children}
    </Popover>
  </React.Fragment>);
})(({ theme }) => ({

}));

interface LikeProps extends IconButtonProps { id: string, onLoadRequest: Function, likeInfo: LikeInfo | undefined }

// https://bobbyhadz.com/blog/react-check-if-element-in-viewport
function useIsInViewport<T extends Element>(ref: React.MutableRefObject<T | null>) {

  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    if (ref.current != null)
      observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

// this may be better off lifted and split into another component that allows disposal of the viewport stuff once loaded
const Like = styled((props: LikeProps) => {
  const { id, onClick, disabled, onLoadRequest, likeInfo, ...other } = props;
  const loaded = props.likeInfo !== undefined;
  const userLiked = props.likeInfo && props.likeInfo.iLike === true;

  let likeRef = useRef<HTMLDivElement>(null)
  const isInViewport = useIsInViewport<HTMLDivElement>(likeRef);

  useEffect(() => {
    if (loaded) return;
    if (!isInViewport) return;
    onLoadRequest();
  }, [loaded, onLoadRequest, isInViewport]);
  // console.log('rendering like:', props.id, props.likeInfo);

  return (<React.Fragment>
    <div ref={likeRef} key={props.id} title={loaded && !disabled ? (userLiked ? 'Unlike Post' : 'Like Post') : 'Likes disabled or failed to load'}>

      <IconButton
        {...other} data-file="CDS"
        disabled={disabled || !loaded}
        onClick={props.likeInfo ? props.onClick : () => { }}>
        {
          userLiked ? <ThumbUpIcon /> : <ThumbUpOIcon />
        }
        <div>{(props.likeInfo && props.likeInfo.likes) ?? ''}</div>
      </IconButton>
      {props.children}
    </div>
  </React.Fragment>);
})(({ theme }) => ({

}));

interface ThumbnailProps extends React.ImgHTMLAttributes<HTMLImageElement> { loaded: boolean, isSmallDevice: boolean }
const CardThumbnail = styled((props: ThumbnailProps) => {
  const { loaded, isSmallDevice, ...other } = props;
  return (
    <LazyLoad once data-file="CDS" height={THUMB_HEIGHT} offset={800} placeholder={<Skeleton sx={{ height: "100%" }} animation="wave" variant="rectangular" />}>
      <img alt="author thumbnail" {...other} loading="lazy" style={{ maxWidth: isSmallDevice ? 315 : undefined }} />
    </LazyLoad>
  )
})(({ theme, loaded }) => ({
  display: 'block',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100%',
  objectFit: 'contain',
  height: THUMB_HEIGHT,
  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
  maxHeight: MAX_HEIGHT,
  opacity: !loaded ? 0 : 1,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.enteringScreen,
  }),
}));


export default Feed;
