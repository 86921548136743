import { ActivityFeedItemProps, ActivityFeedResp } from "../services/DCPS"
import { ServerErrorResponse, isServerErrorResponse } from "./ApiSchema"
import { newDcpsService } from "../util/Services";
import { System } from "../Reusable";
import { formatDate } from "../services/Common";

export type LikeInfo = {
    likes: number
    iLike: boolean
    // likeUrl: string
    // unlikeUrl: string
}

interface PostItemProps {
    thumbnail: string
    title: string
    location: string
    link: string
    date: string
    dateTitle: string
    provider: string
    summary: string
    hasAuthor: boolean
    getLikeInfoUrl: string | undefined
    addLikeUrl: string | undefined
    removeLikeUrl: string | undefined
    likeInfo?: LikeInfo
}

// type alias, additive type demo
// this one prop could be rolled into the above type, but keeping this here to show and explain
export type ServerPostItem = { id: string } & PostItemProps;

// a fresh fetch, initiated by landing, not by searching
export type FetchInit = {
    state: 'init'
    searchText: string
}
export type FetchRequest = {
    state: 'req'
    searchText: string
}
export type FetchContinue = {
    nextUrl: string
}
export type PostFetchArgs =
    | FetchInit
    | FetchRequest
    | FetchContinue

// {
//     nextUrl: string
//     // initial: boolean
//     searchText: string
//     lastSearch: string
//     loading: boolean
// }

export type PostFetchNotifyArgs = PostFetchArgs & {
    // onBeforeLoad: System.Action1<string>
    onNonContinue: System.Action
}

export const END_OF_FEED = "endOFfeed";
export const canLoadPosts = (pfa: PostFetchArgs) => !('nextUrl' in pfa) || pfa.nextUrl !== END_OF_FEED;


interface ReplyResult {
    nextUrl: string
    newItems: ServerPostItem[]
    initiallyLoading: boolean
    loading: boolean
}
type PReplyResult = Partial<ReplyResult>

export type HandleReplyResult = ({ searchChanged: boolean } & PReplyResult) | ServerErrorResponse
// const handleReply = (dispatch:any, [initiallyLoading,setInitiallyLoading]: System.PropWrapper<boolean>, [items, setItems]: System.PropWrapper<ServerPostItem[]>, [nextUrl,setNextUrl]: System.PropWrapper<string>, searchChanged: boolean, res: ActivityFeedResp | ServerErrorResponse) => {

const handleReply = (nextUrl: string, searchChanged: boolean, res: ActivityFeedResp | ServerErrorResponse):
    HandleReplyResult => {
    // console.log("Posts loaded with result: ", res);
    // console.log('handleContentStreamReply');
    let result: Partial<ReplyResult> & { searchChanged: boolean } = { searchChanged };
    if (isServerErrorResponse(res)) {
        console.warn(res);
        return res;
    }
    if (res.Items.length === 0 || !res.Items) {
        result.nextUrl = END_OF_FEED;
    } else if (nextUrl !== res.NextURL) {

        console.log('loading ' + res.Items.length + ' post(s), searchChanged:' + searchChanged, res.NextURL);

        // possible memory leak
        // in case token refreshes, triggering a duplicate load
        result.nextUrl = res.NextURL;

        result.newItems = res.Items.map(mapServerItem);
    }
    result.initiallyLoading = false;

    result.loading = false;

    // console.log('getContentStream done!');
    return result;
}

// export const fetchPosts = async (pfa: PostFetchNotifyArgs): Promise<undefined | HandleReplyResult> => {
export const fetchPosts = async (pfa: PostFetchNotifyArgs): Promise<undefined | ReturnType<typeof handleReply>> => {
    if (!canLoadPosts(pfa)) {
        console.warn('cant load posts', pfa);
        return;
    }

    console.log('loading posts', pfa);

    // pfa.onBeforeLoad(pfa.searchText ?? "");

    var searchChanged = !('nextUrl' in pfa);
    if (searchChanged) {
        pfa.onNonContinue();
    }
    let nextUrl = 'nextUrl' in pfa ? pfa.nextUrl : "";
    let searchText = 'searchText' in pfa ? pfa.searchText : "";
    let result = await newDcpsService.getContentStream(nextUrl ?? "", searchText ?? ""); //.then(res => handleReply(items, searchChanged, res));
    // console.log('getContentStream done');
    return handleReply(nextUrl, searchChanged, result);
};

const mapServerItem = (item: ActivityFeedItemProps): ServerPostItem => {

    var spi: ServerPostItem = {
        id: item.ProviderID,
        thumbnail: item.ImageUrl,
        getLikeInfoUrl: item.LikeInfoUrl,
        addLikeUrl: item.LikeClickUrl,
        removeLikeUrl: item.LikeUnclickUrl,
        hasAuthor: item.HasAuthor,
        title: item.Subject ?? "",
        location: item.ProviderDisplayName ?? "",
        link: item.DisplayUrl,
        // date: formatDate(item.Modified),
        date: item.TimeAgo,
        dateTitle: formatDate(item.Modified),
        provider: item.ProviderDisplayName ?? "",
        summary: item.Summary ?? "",
    };
    // Show nothing if the summary provides no extra insight
    if (spi.title.normalize() === spi.summary.normalize()) {
        spi.summary = "";
    }
    return spi;
}

// does not attempt to remove dupes from existing items, only prevent adding new dupes
export const getItemsNext = (keepPrevious: boolean, existingItems: ServerPostItem[], newItems: ServerPostItem[]): ServerPostItem[] => {
    let existingIds = new Set(keepPrevious ? existingItems.map(x => x.id) : []);
    // if (!getIds().includes(_item.id) && (!keepPrevious || (keepPrevious && !existingIds.includes(_item.id)))) {
    //     _items.push(_item);
    let toMerge = newItems.filter(x => {
        let isDupe = existingIds.has(x.id);
        existingIds.add(x.id);

        return !isDupe;
    });

    if (keepPrevious) {
        // let oldCount = items.length;
        // console.log('merging from ' + oldCount + ' item(s) with ' + _items.length + " new item(s)");

        let nextItems = [...existingItems, ...toMerge];
        const ids = nextItems.map(x => x.id);
        const distinctIds = Array.from(new Set(ids)).sort();
        if (nextItems.length !== distinctIds.length)
            console.warn('duplicate ids found', ids.length, distinctIds.length, ids);
        // console.log('merge completing, ' + oldCount + ' -> ' + items.length + ' item(s)', nextItems.map(x => x.id));
        return nextItems;
    } else {
        // console.log('replacing, expect to see ' + _items.length);
        return toMerge;
        // console.log('replaced with ' + _items.length);
    }
};